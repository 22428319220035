import color from './color';
import spacing from './spacing';
import { fontFamily } from '@dozuki/web-js/primitives';

export default {
   title: {
      default: {
         'font-size': '1.5rem',
         'font-weight': 'bold',
         'font-family': `Inter, ${fontFamily.sansSystem}`,
         'line-height': '1.8125rem',
      },
      medium: {
         'font-size': '1.75rem',
         'font-weight': 'bold',
         'font-family': `Inter, ${fontFamily.sansSystem}`,
         'line-height': '2.125rem',
      },
      large: {
         'font-size': '2rem',
         'font-weight': 'bold',
         'font-family': `Inter, ${fontFamily.sansSystem}`,
         'line-height': '2.375rem',
      },
      xLarge: {
         'font-size': '2.25rem',
         'font-weight': 'bold',
         'font-family': `Inter, ${fontFamily.sansSystem}`,
         'line-height': '2.6875rem',
      },
      xXLarge: {
         'font-size': '2.5rem',
         'font-weight': 'bold',
         'font-family': `Inter, ${fontFamily.sansSystem}`,
         'line-height': '3rem',
      },
   },
   paragraph: {
      'font-size': '1rem',
      'font-family': `Inter, ${fontFamily.sansSystem}`,
      'line-height': '1.5rem',
   },
   blockquote: {
      'font-size': '1rem',
      'font-family': `Inter, ${fontFamily.sansSystem}`,
      'font-style': 'italic',
      'line-height': '1.5rem',
      padding: `0 ${spacing[1]}`,
      'border-left': `2px solid ${color.gray[2]}`,
      'border-radius': '2px',
      color: color.gray[4],
   },
   textLink: {
      'font-family': `Inter, ${fontFamily.sansSystem}`,
      color: color.blue[500],
   },
   monospace: {
      'font-size': '0.875rem',
      'line-height': '1rem',
      'font-family': '"Monaco", "Lucida Console", "Courier New", "Courier", monospace',
      'background-color': color.gray[0],
   },
};
